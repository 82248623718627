import { LoadingComponent } from './utils/loading/loading.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMaskModule } from 'ngx-mask';
import { InmediatePurchaseComponent } from './inmediate-purchase/inmediate-purchase.component';
import { PayComponent } from './inmediate-purchase/pay/pay.component';
import { PrettyCardComponent } from './inmediate-purchase/pretty-card/pretty-card.component';

import { HttpClientModule } from '@angular/common/http';
import { ErrorComponent } from './inmediate-purchase/pay/error/error.component';
import { LoadingService } from './utils/loading/loading.service';

@NgModule({
  declarations: [
    AppComponent,
    PayComponent,
    PrettyCardComponent,
    InmediatePurchaseComponent,
    LoadingComponent,
    ErrorComponent,
  ],
  imports: [
    // AnimationModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    CommonModule,
    MatSnackBarModule,
    MatCardModule,
    MatTooltipModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    HttpClientModule,
  ],
  providers: [LoadingService],
  bootstrap: [AppComponent]
})
export class AppModule { }
