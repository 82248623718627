import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  // API path
  api_path  = '/api/v1';

  constructor(
    private http: HttpClient
  ) { }

  sale(uuid: string): Promise<any>{
    return this.http.get(this.api_path + '/ventas' + `/${uuid}`).toPromise();
  }

  token(request: any): Promise<any>{
    // Http Options
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.api_path + '/tokens', JSON.stringify(request), httpOptions).toPromise();
  }

  pay(request: any): Promise<any>{
    // Http Options
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(this.api_path + '/payments', JSON.stringify(request), httpOptions).toPromise();
  }

  verifyPay(id: string) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    return this.http.post(`${this.api_path}/ventas/verificarEfectivo`, {id}, httpOptions).pipe(
      switchMap((response) => response ? of(response) : throwError(response))
    );
  }

}
