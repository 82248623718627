<div class="page-container">
  <img class="logo-mobile" src="../../../assets/img/cipresPagos.svg" alt="">
  <div class="original-page-mobile">
    <p>Estás comprando en <a href="https://drofar.com.ar" target="_blank">shop.drofar.com.ar</a></p>
  </div>
  <div class="card-container">
    <div class="price-container">
      <div class="info-container">

        <div class="icon">
          <img src="../../../assets/img/cart-icon.svg" alt="">
        </div>
        <p>Total a pagar</p>
        <h2 *ngIf="sale">${{ sale.amount }}</h2>
      </div>
      <div class="original-page">
        <p>Estás comprando en <a href="https://drofar.com.ar" target="_blank">shop.drofar.com.ar</a></p>
      </div>
    </div>
    <!--FORMULARIO DE PAGO-->
    <div class="form-container" [formGroup]="decidirForm">
      <!-- NOMBRE TARJETA -->
      <img class="logo" src="../../../assets/img/cipresPagos.svg" alt="">

      <div class="chooseCardContainer" *ngIf="sale && sale.retiroFarmacia">
        <mat-radio-group formControlName="payment_type">
          <mat-radio-button *ngFor="let pt of paymentTypes" [value]="pt">
            {{ pt.value }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="sale && (!sale.retiroFarmacia || decidirForm.get('payment_type').value.id == 2)">
      <h1>Ingresá los datos de tu tarjeta</h1>
      <div class="flex-container" fxLayout="column" fxLayout.gt-md="row" fxLayoutGap="2rem">
        <app-pretty-card fxFlex.gt-md="20rem" [showBackCard]="showBackCard"
          (onBroadcastChange)="onChangeCardType($event)" [pay_type]="pay_card_types" [decidirForm]="decidirForm">
        </app-pretty-card>

        <!-- <button fxFlex="3.5rem" mat-fab (click)="openCardListeningDialog()" color="primary" type="button">
          ABRIR PANEL DE TARJETA
        </button> -->
        <div class="flex-container">
          <div class="chooseCardContainer">
            <mat-radio-group formControlName="card_type">
              <mat-radio-button value="1">Crédito</mat-radio-button>
              <mat-radio-button value="0">Débito</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="flex-container">
            <div fxFlex class="new-input-container">
              <mat-form-field color="accent">
                <input autocomplete="off" matInput formControlName="card_holder_name"
                  [value]="decidirForm.get('card_holder_name').value" type="text"
                  placeholder="Nombre como figura en la tarjeta">
                <mat-error *ngIf="decidirForm.get('card_holder_name').hasError('required')">Campo obligatorio
                </mat-error>
                <mat-error *ngIf="decidirForm.get('card_holder_name').hasError('pattern')">Ingresá un nombre
                  válido
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-container" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="1rem">

            <!-- NUMEROS TARJETA -->
            <div fxFlex class="new-input-container">
              <mat-form-field color="accent">
                <input autocomplete="off" [mask]="cardNumberMask" matInput formControlName="card_number"
                  placeholder="Número de tarjeta" (keyup)="defineNumberCardRules()">
                <mat-error *ngIf="decidirForm.get('card_number').hasError('required')">Ingresá los 15 números de tu
                  tarjeta
                </mat-error>
                <mat-error *ngIf="decidirForm.get('card_number').hasError('cardType')">Ingresá un numero de tarjeta de
                  una
                  emisora valida</mat-error>
              </mat-form-field>
            </div>
            <!-- VENCIMIENTO TARJETA -->
            <div fxFlex="20%" class="new-input-container">
              <mat-form-field color="accent">
                <input autocomplete="off" type="text" matInput formControlName="card_expiration_date" mask="00/00"
                  placeholder="Vencimiento">
                <mat-error *ngIf="decidirForm.get('card_expiration_date').hasError('required')">Campo obligatorio
                </mat-error>
                <!-- <mat-error *ngIf="decidirForm.get('card_expiration_date').hasError('Mask error')">Error en la mascara</mat-error> -->
              </mat-form-field>
            </div>
            <!-- CODIGO SEGURIDAD TARJETA -->
            <div fxFlex="20%" class="new-input-container">
              <mat-form-field color="accent">
                <input autocomplete="off" matInput formControlName="security_code" (focusin)="changeSide(true)"
                  (focusout)="changeSide(false)" [mask]="securityCodeMask" placeholder="Código de seguridad">
                <mat-error *ngIf="decidirForm.get('security_code').getError('required')">Campo obligatorio
                </mat-error>
                <mat-error *ngIf="decidirForm.get('security_code').getError('maxlength')"> Ingresá un codigo
                  de seguridad válido </mat-error>
                <mat-error *ngIf="decidirForm.get('security_code').getError('minlength')"> Ingresá un codigo
                  de seguridad válido </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="flex-container">
            <div class="new-select-container" [formGroup]="buyForm">
              <mat-form-field color="accent">
                <mat-label>Cuotas</mat-label>
                <mat-select color="accent" formControlName="cantCuotas">
                  <mat-option value="1" color="accent" *ngIf="sale">
                    <span> 1 cuota de $ {{ sale.amount }}</span>
                  </mat-option>

                  <!-- <mat-option value="3" color="accent">
                    <span> 3 cuotas de $ {{ sale.amount3 }}
                      <span class="opcion-cuotas-total"> (Precio total: $ {{sale.amount}})</span>
                    </span>
                  </mat-option> -->

                </mat-select>
                <div class="new-select-arrow">
                  <i class="material-icons">keyboard_arrow_down</i>
                </div>
              </mat-form-field>
            </div>
          </div>
          <!-- DATOS DNI -->
          <div class="flex-container" fxLayout="column" fxLayout.gt-xs="row" formGroupName="card_holder_identification"
            fxLayoutGap.gt-xs="1rem">
            <div class="new-select-container" fxFlex="40">
              <mat-form-field color="accent">
                <mat-label>Tipo de documento</mat-label>
                <mat-select color="accent" formControlName="type" placeholder="Tipo de documento">
                  <mat-option color="accent" *ngFor="let type of identifications" [value]="type">
                    {{type}}
                  </mat-option>
                </mat-select>
                <div class="new-select-arrow">
                  <i class="material-icons">keyboard_arrow_down</i>
                </div>
              </mat-form-field>
            </div>
            <div fxFlex="60" class="new-input-container">
              <mat-form-field color="accent">
                <input autocomplete="off" matInput mask="0000999999" formControlName="number"
                  placeholder="Número documento">
                <mat-error *ngIf="identificationForm.get('number').getError('required')">Campo obligatorio</mat-error>
                <mat-error *ngIf="identificationForm.get('number').getError('Mask error')">Ingresá un dni válido
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="flex-container" *ngIf="paySuccess == false">
            <mat-error>No se pudo procesar el pago. Por favor, revisá los datos de la tarjeta.</mat-error>
          </div>

          <div class="button-container">
            <button mat-flat-button [disabled]="decidirForm.invalid" color="primary" (click)="pay()">Pagar</button>
          </div>
        </div>
      </div>
     </div>
     <div class="button-container" *ngIf="sale && sale.retiroFarmacia && decidirForm.get('payment_type').value.id == 1">
      <button mat-flat-button  color="primary" (click)="payLocal()">Confirmar</button>
     </div>
    </div>
  </div>
</div>
