<div class="card">
  <div class="card__front card__part card__size" #creditCardFront
      [class.background__amex]="isAmex"
      [class.background__visa]="isVisa"
      [class.background__master]="isMaster"
      [class.background__cabal]="isCabal"
      [class.background__default]="!cardsBroadcast">

    <img class="card__square" src="../../../assets/img/card/little_square.png">
    <img *ngIf="cardImage" class="card__front-logo card__logo" [src]="cardImage">

    <p class="card__front_code">  </p>

    <p class="card_numer" *ngIf="!cardNumber" > •••• •••• •••• •••• </p>
    <p class="card_numer" *ngIf="cardNumber" > {{ cardNumber | mask: cardNumberMask }} </p>

    <div class="card__space-75">
      <p class="card__info"> {{cardName ? cardName : 'Nombre Completo'}} </p>
    </div>
    <div class="card__space-25">
      <p class="card__info">{{ cardExpirationDate ? (cardExpirationDate | mask: '00/00') : 'MM/AA' }}</p>
    </div>
  </div>
  <div class="card__back card__part card__size" #creditCardBack
        [class.background__amex]="isAmex"
        [class.background__cabal]="isCabal"
        [class.background__visa]="isVisa"
        [class.background__master]="isMaster"
        [class.background__default]="!cardsBroadcast">
    <div class="card__back-content">
      <div class="card__secret">
        <p class="card__secret--last"> {{securityCode ? securityCode : '•••'}} </p>
      </div>
      <img class="card__back-square card__square" src="../../../assets/img/card/little_square.png">
    </div>
  </div>
</div>
