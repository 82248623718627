import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pretty-card',
  templateUrl: './pretty-card.component.html',
  styleUrls: ['./pretty-card.component.scss']
})
export class PrettyCardComponent implements OnInit, OnChanges {

  @ViewChild('creditCardFront', {static: true}) creditCardFront: ElementRef;
  @ViewChild('creditCardBack', {static: true}) creditCardBack: ElementRef;

  @Input() decidirForm: FormGroup;
  @Input() cardsBroadcast: number;
  // tslint:disable-next-line: variable-name
  @Input() pay_type: any;
  @Input() showBackCard = false;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onBroadcastChange = new EventEmitter<number>();

  cardImage: string;
  cardNumberMask: string = '0000 0000 0000 0000';

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
    this.onCardNumberChange();
  }

  onFormReady(decidirForm: FormGroup) {
    this.decidirForm = decidirForm;
  }

  onCardNumberChange() {
    this.decidirForm.get('card_number').valueChanges
      .subscribe((value: string) => {
        this.defineCardType(value);
      });
  }

  onCardSideChange(showCardCurrenValue: boolean) {
    showCardCurrenValue ? this.onShowBackCard() : this.onShowFrontCard();
  }

  onShowBackCard() {

      this.renderer.addClass(this.creditCardFront.nativeElement, 'card-testIn');
      this.renderer.addClass(this.creditCardBack.nativeElement, 'card-testOut');
  }

  onShowFrontCard() {
      this.renderer.removeClass(this.creditCardFront.nativeElement, 'card-testIn');
      this.renderer.removeClass(this.creditCardBack.nativeElement, 'card-testOut');
  }

  defineCardType(onChangeValue?: string) {
    // if (this.pay_type !== null && this.pay_type !== undefined) {
      const value = onChangeValue ? onChangeValue : this.decidirForm.get('card_number').value;

      if (value != null) {
        const letters = value.substr(0, 2);
        const lettersCabal = value.substr(0, 4);
        this.cardNumberMask = '0000 0000 0000 0000';
        if (letters === '34' || letters === '37') { // Amex
          this.cardImage = '../../../assets/img/card/amex_png.png';
          this.cardsBroadcast = 16;
          this.cardNumberMask = '0000 000000 00000';
          // this.cardMaxLength = 15;
        } else if (value.substr(0, 1) === '4') { // Visa
          this.cardImage = '../../../assets/img/card/visa_logo.png';
          this.cardsBroadcast = 16;
          // this.cardsBroadcast = this.pay_type.VISA;
          // this.cardMaxLength = 16;
        } else if (letters === '6035' || letters === '6042' || lettersCabal == '5896') { // Amex
          this.cardImage = '../../../assets/img/card/cabal_logo.png';
          this.cardsBroadcast = 16;
          this.cardNumberMask = '0000 000000 00000';
          // this.cardMaxLength = 15;
        }
        else if (letters === '50' || letters.substr(0, 1) === '5' || letters === '55') { // MCard
          this.cardImage = '../../../assets/img/card/mcard_logo.png';
          this.cardsBroadcast = 16;
          // this.cardsBroadcast = this.pay_type.MASTER;
        }
        else {
          this.cardsBroadcast = null;
          this.cardImage = null;
          this.decidirForm.get('card_number').setErrors({cardsBroadcast: true});
        }
        // this.onBroadcastChange.emit(this.cardsBroadcast);
      }
    // } else { this.onBroadcastChange.emit(0); }
  }

  // GETTERS

  get cardNumber() {
    return this.decidirForm.get('card_number').value;
  }

  get cardName() {
    return this.decidirForm.get('card_holder_name').value;
  }

  get securityCode() {
    return this.decidirForm.get('security_code').value;
  }

  get cardExpirationDate() {
    return this.decidirForm.get('card_expiration_date').value;
  }

  get isVisa() {
      return this.decidirForm.get('card_number').value.substr(0, 1) === '4';
  }

  get isMaster() {
    const letters = this.decidirForm.get('card_number').value.substr(0, 2);
    return letters === '50' || letters.substr(0, 1) === '5' || letters === '55';
  }

  get isCabal() {
    // const letters = this.decidirForm.get('card_number').value.substr(0, 2);
    const lettersCabal = this.decidirForm.get('card_number').value.substr(0, 4);
    return lettersCabal === '6035' || lettersCabal === '6042' || lettersCabal == '5896';
  }

  get isAmex() {
    const letters = this.decidirForm.get('card_number').value.substr(0, 2);
    return (letters === '34' || letters === '37')
  }

  ngOnChanges(changes: SimpleChanges) {
    let pay_typeChanges = changes['pay_type'];
    const showBackCard = changes['showBackCard'];

    if (pay_typeChanges) {
      this.defineCardType();
    }

    if (showBackCard) {
      this.onCardSideChange(showBackCard.currentValue);
    }
  }
}
