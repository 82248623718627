import { Component, OnInit, Input } from '@angular/core';
import { LoadingService } from './loading.service';

@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input()
  nombre: string = "default";
  dialog: boolean;

  constructor(private _loadingService: LoadingService) {
  }

  ngOnInit() {
    if (!this._loadingService.get(this.nombre)) {
      this._loadingService.register(this.nombre)
    }
  }

  ngOnDestroy() {}

  get isLoading() {
    return this._loadingService.get(this.nombre);
  }

}
