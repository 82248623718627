import {LoadingService} from './../../utils/loading/loading.service';
// import { CardListeningComponent } from './../card-listening/card-listening.component';
import {FormBuilder, Validators, FormGroup, FormControl} from '@angular/forms';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  HostListener,
  ViewChild,
  ElementRef
} from '@angular/core';
import {PAY_TYPE} from '../PAY_TYPES';
import {catchError, debounceTime, filter, finalize} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ApiService} from 'src/app/services/api.service';
import {ActivatedRoute} from '@angular/router';
import {ErrorComponent} from './error/error.component';
import {Location} from '@angular/common';
import {of} from 'rxjs';

interface IFees {
  cantCuotas: number;
  valorCuota: number;
  precioTotal: number;
}

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss']
})
export class PayComponent implements OnInit {

  @Input() fees: IFees[];
  @Input() pay_card_types: any;
  @Input() currentStep: number;

  @Output() formReady = new EventEmitter<FormGroup>();
  @Output() onDecidirReady = new EventEmitter<FormGroup>();
  @Output() onCardTypeChange = new EventEmitter<FormControl>();
  @Output() onStatusChange = new EventEmitter<string>();


  identifications = ['DNI', 'Libreta cívica'];
  tipoTarjeta = new FormControl('');
  showBackCard: boolean;

  paySuccess: boolean = null;
  sale: any = null;
  cardNumberMask = '0000 0000 0000 0000';
  securityCodeMask = '0000';
  @ViewChild('focusable', {static: true}) inputElement: ElementRef;
  cardInfo = '';
  retiroFarmacia: boolean = null;
  paymentTypes = [
    {id: 1, value: 'Efectivo en el local'},
    {id: 2, value: 'Tarjeta (Online)'}
  ];
  // loading = true;
  idSolicitud: string;

  paymentMethodId: number;

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private loadingService: LoadingService
  ) {
  }

  decidirForm = this.fb.group({
    card_number: ['', [Validators.required]],
    card_expiration_date: ['', [Validators.required]],
    security_code: ['', [Validators.required]],
    card_holder_name: ['', [Validators.required, Validators.pattern('[a-z A-Z]{0,50}')]],
    card_holder_identification: this.fb.group({
      type: ['DNI', [Validators.required]],
      number: ['', [Validators.required]]
    }),
    card_type: ['1', [Validators.required]],
    payment_type: [{id: null}]
  });

  buyForm = this.fb.group({
    tipoPago: [null],
    cantCuotas: ['1'],
  });

  async ngOnInit() {
    this.loadingService.show();
    this.idSolicitud = this.activatedRoute.snapshot.params.token;
    try {
      this.sale = await this.apiService.sale(this.idSolicitud);
    } catch (error) {
      this.openErrorDialog(error.error);
    } finally {
      // this.loading = false;
      this.loadingService.hide();
    }

    // this.openCardListeningDialog();

    this.formReady.emit(this.buyForm);
    this.onDecidirReady.emit(this.decidirForm);

    this.decidirForm.statusChanges
      .pipe(debounceTime(200))
      .subscribe(status => {
        if (this.currentStep == 4) {
          this.onStatusChange.emit(status);
        }
      });

  }

  onChangeCardType(card_type) {
    this.tipoTarjeta.setValue(card_type);
    this.onCardTypeChange.emit(this.tipoTarjeta);
  }

  openErrorDialog(error: string) {
    const dialogRef = this.dialog.open(ErrorComponent, {
      disableClose: true,
      data: {message: error}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.location.back();
    });
  }

  // openCardListeningDialog() {
  //  let dialogRef = this.dialog.open(CardListeningComponent, {
  //    // autoFocus: true,
  //    disableClose: true
  //  });
  //  dialogRef.afterClosed()
  //  .subscribe(result => {
  //    if (result) {
  //      this.decidirForm.patchValue({
  //        card_number: result.cardNumber,
  //        card_expiration_date: result.cardDate,
  //        security_code: '',
  //        card_holder_name: result.cardName,
  //      });
  //    }
  //  });
  // }

  changeSide(value: boolean) {
    this.showBackCard = value;
  }

  get identificationForm(): FormGroup {
    return this.decidirForm.get('card_holder_identification') as FormGroup;
  }

  async getToken() {
    try {
      const body = {
        card_number: this.decidirForm.get('card_number').value,
        security_code: this.decidirForm.get('security_code').value,
        card_expiration_month: this.decidirForm.get('card_expiration_date').value.substring(0, 2),
        card_expiration_year: this.decidirForm.get('card_expiration_date').value.substring(2, 4),
        card_holder_name: this.decidirForm.get('card_holder_name').value,
        card_holder_identification: {
          type: this.decidirForm.get('card_holder_identification').get('type').value,
          number: this.decidirForm.get('card_holder_identification').get('number').value
        },
        device_unique_identifier: navigator.userAgent
      };
      return await this.apiService.token(body);
    } catch (error) {
      this.paySuccess = false;
      return {status: 'error'};
    }
  }

  async pay() {
    this.loadingService.show();
    const resptoken = await this.getToken();
    try {
      if (resptoken.status == 'active') {
        const body = {
          customer: {
            email: this.sale.customer.email,
            id: this.sale.customer.id
          },
          amount: this.sale.amount,
          bin: this.decidirForm.get('card_number').value.substring(0, 6),
          paymentMethodId: this.defineCardType(),
          siteTransactionId: this.sale.siteTransactionId,
          token: resptoken.id,
          idSolicitud: this.idSolicitud,
          nrTarjetaInicio: this.cardNumber.substring(0, 4),
          nrTarjetaFin: this.cardNumber.substring(this.cardNumber.length - 4),
          installment: this.buyForm.get('cantCuotas').value
        };
        const resp = await this.apiService.pay(body);
        this.paySuccess = true;
        this.paymentMethodId = body.paymentMethodId;
        let url = new URL(this.sale.url_ok);
        let params = url.searchParams;
        params.set('payment_method', this.paymentMethodId.toString());
        window.location.href = url.href;
        this.loadingService.hide();
      } else {
        this.loadingService.hide();
        this.paySuccess = false;
      }
    } catch (error) {
      this.loadingService.hide();
      window.location.href = this.sale.url_error;
    }
  }

  payLocal() {
    this.loadingService.show();
    this.apiService.verifyPay(this.idSolicitud).pipe(
      finalize(() => this.loadingService.hide()),
      catchError((httpError) => {
        window.location.href = this.sale.url_error;
        return of(null);
      }),
      filter((response: any) => response)
    )
      .subscribe(
        () => window.location.href = this.sale.url_ok + '&payment_method=2'
      );
  }

  defineNumberCardRules() {
    const value = this.decidirForm.get('card_number').value.substr(0, 2);
    if (value === '34' || value === '37') {
      this.securityCodeMask = '0000';
      this.cardNumberMask = '0000 000000 00000';
    } else {
      this.securityCodeMask = '000';
      this.cardNumberMask = '0000 0000 0000 0000';
    }
  }

  defineCardType() {
    const value = this.decidirForm.get('card_number').value;
    const letters = value.substr(0, 2);
    const lettersCabal = value.substr(0, 4);
    if (letters === '34' || letters === '37') {
      return (this.decidirForm.get('card_type').value == 0)
        ? PAY_TYPE.TIPOS_TARJETA.DEBITO.AMEX
        : PAY_TYPE.TIPOS_TARJETA.CREDITO.AMEX;
    } else if (value.substr(0, 1) === '4') {
      return (this.decidirForm.get('card_type').value == 0)
        ? PAY_TYPE.TIPOS_TARJETA.DEBITO.VISA
        : PAY_TYPE.TIPOS_TARJETA.CREDITO.VISA;
    } else if (letters === '6035' || lettersCabal == '6042' || lettersCabal == '5896') {
      return (this.decidirForm.get('card_type').value == 0)
        ? PAY_TYPE.TIPOS_TARJETA.DEBITO.CABAL
        : PAY_TYPE.TIPOS_TARJETA.CREDITO.CABAL;
    } else if (letters === '50' || letters.substr(0, 1) === '5' || letters === '55') {
      return (this.decidirForm.get('card_type').value == 0)
        ? PAY_TYPE.TIPOS_TARJETA.DEBITO.MASTER
        : PAY_TYPE.TIPOS_TARJETA.CREDITO.MASTER;
    }

  }

  @HostListener('window:keydown', ['$event'])
  listenCardEvent(event: KeyboardEvent) {
    if (event.which === 16 || event.which === 32) {
      return;
    } else if (event.which === 13) {
      this.cardParserData();
      return;
    }
    this.cardInfo += event.key === 'Dead' ? '^' : event.key;
  }

  cardParserData(): void {
    setTimeout(() => {
      if (this.inputElement) {
        this.inputElement.nativeElement.click();
      }
    }, 0);
    const cardData = this.cardInfo.split('^');
    if (cardData.length > 1) {
      this.cardInfo = '';
      this.decidirForm.get('card_number').setValue(cardData[0].substr(2));
      this.decidirForm.get('card_holder_name').setValue(cardData[1].replace('/', ' '));
      const dateData = cardData[2].substr(0, 4);
      this.decidirForm.get('card_expiration_date').setValue(`${dateData.substr(2, 2)}/${dateData.substr(0, 2)}`);
    }
  }

  get cardNumber() {
    return this.decidirForm.get('card_number').value;
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   let currentStepChanges = changes['currentStep'];

  //   if (currentStepChanges != null && currentStepChanges.currentValue == 4) {
  //     this.onStatusChange.emit(this.decidirForm.status);
  //     setTimeout(() => {
  //       this.openCardListeningDialog();
  //     }, 500);
  //   }
  // }
}
