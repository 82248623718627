import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-inmediate-purchase',
  templateUrl: './inmediate-purchase.component.html',
  styleUrls: ['./inmediate-purchase.component.scss']
})
export class InmediatePurchaseComponent implements OnInit {
  userSearch = true;
  dniControl = new FormControl('');

  constructor(private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {}

}
