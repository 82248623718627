export const PAY_TYPE = {
  TODO_PAGO: 100,
  TIPOS_TARJETA: {
    DEBITO: {
      VISA: 31,
      MASTER: 105,
      CABAL: 108,
      AMEX: null
    },
    CREDITO: {
      VISA: 1,
      MASTER: 104,
      CABAL: 63,
      AMEX: 65
    }
  },
  EFECTIVO: 0
}
